<template>
  <div class="agency-sidebar">
    <sidebar
      v-if="permission"
      :homepage="'PartnershipPropertyListing'"
      :showLogo="false"
      @toChangelog="openChangelogModal"
    >
      <template #top>
        <div
          class="d-flex justify-content-center align-items-center bg-white p-2"
          :class="{ 'd-none': !$store.getters.getSidebarState }"
        >
          <router-link :to="{ name: 'Entry' }">
            <img
              :src="require('@/assets/image/logo/logo.png')"
              :style="{ width: '32px' }"
            />
          </router-link>
          <div class="panel-name">Agency</div>
        </div>
      </template>
      <!-- ===================== Manage Agency ===================== -->
      <sidebar-separator
        v-if="showManagementSeperator"
        label="Management"
      ></sidebar-separator>
      <sidebar-item
        v-if="permission.agencyProfile.update"
        iconClass="fas fa-briefcase"
        label="Agency"
        :to="{ name: 'AgencyProfile' }"
      ></sidebar-item>
      <sidebar-item
        v-if="permission.report.view"
        iconClass="fas fa-file-alt"
        label="Sales Report"
        :to="{ name: 'AgencySalesReport' }"
      ></sidebar-item>
      <!-- ===================== Manage Branch ===================== -->
      <sidebar-dropdown
        v-if="permission.agencyBranch.view || permission.agencyBranch.create"
        label="Branch"
        iconClass="fas fa-code-branch"
      >
        <sidebar-item
          v-if="permission.agencyBranch.view"
          label="Manage Branch"
          :to="{ name: 'ManageBranch' }"
        ></sidebar-item>
        <sidebar-item
          v-if="permission.agencyBranch.create"
          label="Add Branch"
          :to="{ name: 'ManageBranchAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ==================== Manage Agency Users ==================== -->
      <sidebar-dropdown
        v-if="permission.agencyUser.view"
        label="Agency User"
        iconClass="fas fa-user-tie"
      >
        <sidebar-item
          v-if="permission.agencyUser.view"
          label="Manage Agency Users"
          :to="{ name: 'ManageAgencyUsers' }"
        ></sidebar-item>
        <sidebar-item
          v-if="permission.agencyUser.create"
          label="Add Agency Users"
          :to="{ name: 'ManageAgencyUsersAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>

      <!-- ===================== Manage Appointment ===================== -->
      <!-- <sidebar-dropdown
            label="Appointment"
            iconClass="fas fa-calendar-check"
          >
            <sidebar-item
              label="Manage Appointment"
              :to="{ name: 'Appointment' }"
            ></sidebar-item>
            <sidebar-item
              label="Add Appointment"
              :to="{ name: 'AppointmentAdd' }"
            ></sidebar-item>
          </sidebar-dropdown> -->
      <!-- ===================== Manage Roles ===================== -->
      <sidebar-item
        v-if="permission.agencyUserRole.view"
        iconClass="fas fa-user-tag"
        label="Roles & Permissions"
        :to="{ name: 'ManageAgencyRole' }"
      ></sidebar-item>

      <!-- ======================= Manage Projects ======================= -->
      <sidebar-separator label="Project"></sidebar-separator>

      <sidebar-dropdown
        v-if="permission.project.view"
        label="Project"
        iconClass="fas fa-folder"
      >
        <sidebar-item
          v-if="permission.project.view"
          label="View Projects"
          :to="{ name: 'ManageProjects' }"
        ></sidebar-item>
        <sidebar-item
          v-if="permission.project.create"
          label="Add New Project"
          :to="{ name: 'ManageProjectsAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>

      <sidebar-dropdown label="Project Sales" iconClass="ri-price-tag-2-line">
        <sidebar-item
          v-if="permission.projectPurchase.manage"
          label="Manage Project Sales"
          :to="{ name: 'ManageProjectSales' }"
          :exact="false"
        ></sidebar-item>
        <sidebar-item
          label="My Project Sales"
          :to="{ name: 'ManageAssignedProjectSales' }"
          :exact="false"
        ></sidebar-item>
        <sidebar-item
          v-if="permission.projectPurchase.manage"
          label="Refund History"
          :to="{ name: 'ProjectRefundHistory' }"
          :exact="false"
        ></sidebar-item>
      </sidebar-dropdown>
      <sidebar-item
        label="Assigned Units"
        iconClass="fas fa-clipboard-list"
        :to="{ name: 'ManageAssignedProjectUnits' }"
      ></sidebar-item>

      <sidebar-dropdown label="Comments" iconClass="far fa-comment-alt">
        <sidebar-item
          v-if="permission.projectUnitFloorPlanComment.changeStatus"
          label="Manage Agent Comments"
          :to="{ name: 'ManageFloorPlanComments' }"
        ></sidebar-item>
        <sidebar-item
          label="My Comments"
          :to="{ name: 'MyFloorPlanComments' }"
        ></sidebar-item>
      </sidebar-dropdown>

      <sidebar-dropdown label="Video" iconClass="fas fa-video">
        <sidebar-item
          v-if="permission.projectUnitFloorPlanVideo.changeStatus"
          label="Manage Agent Videos"
          :to="{ name: 'ManageFloorPlanVideos' }"
        ></sidebar-item>
        <sidebar-item
          label="My Videos"
          :to="{ name: 'MyFloorPlanVideos' }"
        ></sidebar-item>
      </sidebar-dropdown>

      <!-- ==================== Partnership Listing ==================== -->
      <sidebar-separator label="Sell/Rent"></sidebar-separator>

      <sidebar-dropdown label="ACN" iconClass="fas fa-circle-nodes">
        <sidebar-item label="Leads" :to="{ name: 'Leads' }"></sidebar-item>
        <sidebar-item label="My ACN" :to="{ name: 'ACN' }"></sidebar-item>
        <sidebar-item
          label="Public Jobs"
          :to="{ name: 'ACNPublicJobs' }"
        ></sidebar-item>
        <sidebar-item
          label="My Job Applications"
          :to="{ name: 'ACNMyJobApplications' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ==================== Manage Property ==================== -->
      <sidebar-dropdown label="Property" iconClass="fas fa-building">
        <sidebar-item
          label="Manage Property"
          :to="{ name: 'ManageProperty' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Property"
          :to="{ name: 'ManagePropertyAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ==================== Subsales, Rent, Auction ==================== -->
      <sidebar-dropdown label="Sales & Booking" iconClass="ri-hand-coin-line">
        <sidebar-item
          label="Subsales Booking"
          :to="{ name: 'SubsalesBookingList' }"
          :exact="false"
        ></sidebar-item>
        <!--  Rents  -->
        <sidebar-item
          label="Rent Booking"
          :to="{ name: 'RentBookingList' }"
          :exact="false"
        ></sidebar-item>

        <!-- ==================== Auction Registrations ===================== -->
        <sidebar-item
          v-if="permission.manageAuction.enable"
          label="Auction Registration"
          :to="{ name: 'AuctionRegistrationList' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ======================== Archived Sales ======================= -->
      <sidebar-dropdown label="Archived Sales" iconClass="fas fa-archive">
        <!-- Rents -->
        <sidebar-item
          label="Archived Rent"
          :to="{ name: 'ArchivePropertyRentList' }"
          :exact="false"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ===================== Exclusive Submission ====================== -->
      <sidebar-item
        v-if="permission.managePropertyExclusive.enable"
        iconClass="ri-mail-star-line"
        label="Exclusive Submissions"
        :to="{ name: 'ManageExclusiveSubmissions' }"
      ></sidebar-item>

      <!-- ==================== Manage Account ===================== -->
      <sidebar-separator label="Misc"></sidebar-separator>
      <sidebar-item
        label="Account"
        iconClass="fas fa-user-circle"
        :to="{ name: 'ManageAccount' }"
      ></sidebar-item>
      <!-- =================== Mailing Interface =================== -->
      <sidebar-item>
        <a
          class="item-row"
          href="https://smtp7.mschosting.com/"
          target="_blank"
        >
          <i class="fas fa-envelope-square icon"></i>
          <label>Email</label>
        </a>
      </sidebar-item>
    </sidebar>

    <!-- Changelog Modal -->
    <modal v-model="changelog.isModalActive" fullscreen>
      <modal-content class="card">
        <template #body>
          <Changelog class="container fluid p-2"></Changelog>
        </template>
        <template #footer>
          <div class="d-flex justify-content-end p-2">
            <fd-button class="main medium" @click="closeChangelogModal">
              Close
            </fd-button>
          </div>
        </template>
      </modal-content>
    </modal>
  </div>
</template>

<script>
export default {
  components: {
    Sidebar: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/Sidebar.vue"
      ),
    SidebarItem: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarItem.vue"
      ),
    SidebarDropdown: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarDropdown.vue"
      ),
    SidebarSeparator: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarSeparator.vue"
      ),
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent"),
    Changelog: () => import("@/components/GlobalComponents/Changelog")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      permission: null,
      changelog: {
        isModalActive: false
      }
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    },
    showManagementSeperator() {
      return (
        this.permission.agencyProfile.update ||
        this.permission.agencyBranch.view ||
        this.permission.agencyUser.view ||
        this.permission.agencyUserRole.view
      );
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);

      this.permission = await this.$store.dispatch(
        "manageAccount/getAccountPermission"
      );
      this.$store.commit("setIsLoading", false);
    },
    openChangelogModal() {
      this.changelog.isModalActive = true;
    },
    closeChangelogModal() {
      this.changelog.isModalActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.agency-sidebar {
  .panel-name {
    font-size: 14px;
    font-weight: bold;
    color: #555;
    text-transform: uppercase;
    padding: 6px 12px;
  }
}
</style>
